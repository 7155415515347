import { dateFormat } from "@/mixins/dateFormat";
import { willRelationshipHelpers } from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";

export const shareRecipientHelpers = {
    mixins: [dateFormat, willRelationshipHelpers, saveHelpers],
    mounted () {
        // calculate people options
        // this.setOptions()
    },
    watch: {
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptions()
            }
        }
    },
    data () {
        return {
            primaryOptions: [],
            secondaryOptions: []
        }
    },
    computed: {
        storePeople() {
            return this.$store.getters.people
        },
        hasCharityInPrimary () {
            return !!this.form.recipients.filter(recipient => recipient.type === 'charity').length
        },
        showPerStirpesOptions () {
            return !this.hasCharityInPrimary
        },
        showBackupOptions () {
            return !this.hasCharityInPrimary && this.form.recipients.length
        },
        recipientsMoreThanOne () {
            if (this.form.recipients.length > 1) return true
            return !!this.form.recipients.filter(option => option.type === 'group').length;
        },
        backupRecipientsMoreThanOne () {
            if (this.form.backupRecipients.length > 1) return true
            return !!this.form.backupRecipients.filter(option => option.type === 'group').length;
        }
    },
    methods: {
        setOptions() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []

            // convert people
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfGuardians in final form for will
                if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            })

            // filter out primary or secondary options
            let primaryIds = this.form.recipients.map(person => person.id)

            serverPrimary = serverPeople.filter(person => !this.isChildOf(person) && !primaryIds.includes(person.id))

            // return
            this.primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
        }
    }
}