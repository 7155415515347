<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :options="booleanYesNo"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event"/>
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {general} from "../../../options/general";
import {personHelpers} from "@/mixins/personHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'ChildrenSecondaryYN',
  mixins: [
    general,
    questionLoadHelpers,
    willPeopleHelpers,
    willRelationshipHelpers,
    personHelpers,
    dateFormat
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      let beneficiaries = this.children.length > 1 ? 'beneficiaries' : 'beneficiary'
      return {
        title: `If ${this.partner.name_first} has passed away before you (or at the same time) would you want to name ` +
            `${this.listPeopleAddressDob({people: this.children, noDob: true})} as your ${beneficiaries}?`,
        subTitle: `It is usual to name children as beneficiaries after your partner has passed away.`,
        tip: null,
      }
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: 'secondaryChildrenDistributionYN',
        formPath: 'data.distributionSecondary.details.childrenSecondaryYN',
        jsonSaveField: 'childrenSecondaryYN',
        callbackFunction: this.callbackFunction
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
