<template>
  <div>
    <FullName
        :id="'name_first'+objectId"
        :noSave="noSave"
        :objectId="objectId"
        :value-first="form.name_first"
        :value-middle="form.name_middle"
        :value-last="form.name_last"
        :errors-post-first="errorsPost.name_first || []"
        :errors-post-middle="errorsPost.name_middle || []"
        :errors-post-last="errorsPost.name_last || []"
        @inputFirst="form.name_first=$event"
        @inputMiddle="form.name_middle=$event"
        @inputLast="form.name_last=$event"
    />

    <RelationshipChild
        :id="'relationship_to_client'+objectId"
        v-model="form.relationship_to_client"
        :noSave="noSave"
        :errors-post="errorsPost.relationship_to_client || []"
        :objectId="objectId"
    />

    <Parents
        :id="'clients'+objectId"
        v-model="form.clients"
        :no-save="noSave"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
    />

    <Dob
        :id="'dob'+objectId"
        v-model="form.dob"
        :noSave="noSave"
        :errors-post="errorsPost.dob || []"
        :objectId="objectId"
    />

    <Gender
        :id="'gender'+objectId"
        v-model="form.gender"
        :noSave="noSave"
        :objectId="objectId"
        :errors-post="errorsPost.gender || []"
    />

    <Dependant
        :id="'dependant'+objectId"
        v-model="form.dependant"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.dependant || []"
    />

    <DependantUntil
        :id="'dependant_until'+objectId"
        v-if="form.dependant"
        v-model="form.dependant_until"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.dependant_until || []"
    />

    <Email
        :id="'email'+objectId"
        v-model="form.email"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.email || []"
    />
    <Phone
        :id="'phone'+objectId"
        v-model="form.phone"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.phone || []"
    />

    <AddressPerson
        :id="'address'+objectId"
        v-model="form.address"
        :form="form"
        :errors-post="errorsPost.address || []"
        :object-id="objectId"
    />

  </div>
</template>

<script>
import FullName from "../../../question/questions/clientPerson/FullName";
import Dob from "../../../question/questions/clientPerson/Dob";
import Gender from "../../../question/questions/clientPerson/Gender";
import Dependant from "../../../question/questions/clientPerson/Dependant";
import DependantUntil from "../../../question/questions/clientPerson/DependantUntil";
import Email from "../../../question/questions/clientPerson/Email";
import Phone from "../../../question/questions/clientPerson/Phone";
import RelationshipChild from "../../../question/questions/clientPerson/RelationshipChild";
import AddressPerson from "../../../question/questions/clientPerson/AddressPerson";
import Parents from "../../../question/questions/clientPerson/Parents";

export default {
  name: 'ChildFull',
  components: {Parents, AddressPerson, RelationshipChild, Phone, Email, DependantUntil, Dependant, Gender, Dob, FullName},
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: [Number, String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>