<template>
  <QuestionBase :errors="[...jsErrors]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :db="db"
                :options="options" />
  </QuestionBase>
</template>

<script>
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {textHelpers} from "@/mixins/textHelpers";
import {personHelpers} from "@/mixins/personHelpers";
import {shareSaveHelpers} from "./shareSaveHelpers";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'ShareBackupYN',
  components: {
    InputRadio,
    QuestionBase
  },
  mixins: [
    questionLoadHelpers,
    blankForms,
    textHelpers,
    shareSaveHelpers,
    saveHelpers,
    personHelpers
  ],
  props: {
    value: {
      type: Boolean,
      required: false
    },
    location: {
      type: String,
      required: true
    },
    share: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    shares: {
      type: Array,
      required: true
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}Shares${this.index}BackupsYN`,
        formPath: `data.${this.location}.details.shares.${this.index}.backupsYN`,
        jsonSaveField: 'backupsYN',
        callbackFunction: () => {
          this.callbackFunction(this.location,
              this.index)
        }
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      sharesMainInput: [],
      addShareYN: null,
      show: {
        addPerson: false
      },
    }
  },
  computed: {
    question() {
      let questionTitle = ''
      let people = this.listPeopleAddressDob({
        people: this.share.recipients,
        noDob: true,
        firstName: true
      })

      if (this.share.stirpes && (this.otherBeneficiaries.length || this.multipleRecipients)) {

        if (this.otherBeneficiaries.length === 1) {
          questionTitle = `And if there are not any children to inherit should this ${this.share.share} share pass to ${this.otherPeople(true)}?`
        } else questionTitle = `And if there are not any children to inherit should this ${this.share.share} share be split between ${this.otherPeople(true)}?`

      } else if (this.otherBeneficiaries.length || this.multipleRecipients) {
        if (this.otherBeneficiaries.length === 1) {
          questionTitle = `So if ${people} can't inherit would you like this ${this.share.share} share to pass to ${this.otherPeople(true)} instead?`
        } else questionTitle = `So if ${people} can't inherit would you like this ${this.share.share} share to be split between ${this.otherPeople(true)} instead?`
      } else if (this.share.stirpes && (!this.otherBeneficiaries.length && !this.multipleRecipients)) {


        questionTitle = `If their are no children to inherit would you like appoint specific backups?`


      } else {
        questionTitle = `If their are no children to inherit would you like appoint specific backups?`
      }

      return {
        title: questionTitle,
        subTitle: null,
        tip: null,
      }
    },
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    hasGroupInRecipients() {
      // this share contains a group?
      return !!this.share.recipients.filter(option => option.type === 'group').length
    },
    multipleRecipients() {
      // if their more than one person in this share
      return this.share.recipients.length > 1 || this.hasGroupInRecipients
    },
    soleRecipient() {
      // returns the only person in this share
      if (this.share.recipients.length === 1) {
        return this.share.recipients[0]
      }
      return false
    },
    otherShares() {
      // other shares
      let shares = this.clone(this.shares)
      shares.splice(this.index,
          1)
      return shares
    },
    otherBeneficiaries() {
      // other beneficiaries in the other shares
      return this.otherShares.reduce((result,
                                      share) => share.recipients.concat(result),
          [])
    },
    options() {
      return [
        {
          // label: this.otherBeneficiaries.length ? this.capitalise(this.otherPeople()) : 'No',
          label: 'Yes',
          value: false
        },
        {
          // label: this.otherBeneficiaries.length ? 'Other Backup' : 'Yes',
          label: 'No',
          value: true
        }
      ]
    }
  },
  methods: {
    otherPeople(appendThe = false) {
      // either other people in this shares or the other beneficiaries in the other shares
      let others = null

      if (this.multipleRecipients) {
        others = `${appendThe ? 'the ' : ''}other people in this Share`
      } else if (this.otherBeneficiaries.length) {
        others = this.listPeopleAddressDob({
          people: this.otherBeneficiaries,
          noDob: true,
          firstName: true,
        })
      } else {
        others = 'PASS TO SECONDARY'
      }
      return others
    },
  }
}
</script>
