import { render, staticRenderFns } from "./ShareObjects.vue?vue&type=template&id=03a576b0"
import script from "./ShareObjects.vue?vue&type=script&lang=js"
export * from "./ShareObjects.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports