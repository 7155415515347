<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Funeral</h3>

    <FuneralType v-model="form.data.funeral.details.typeOption" />
    <DonorType v-model="form.data.funeral.details.donorOption" />
    <StyleType v-model="form.data.funeral.details.styleOptions" @input="setStyleOptions" />
    <CustomWishesYN v-model="form.data.funeral.details.customWishesYN" />
    <CustomWishes
        v-if="details.customWishesYN"
        v-model="form.data.funeral.details.customWishesOption" />

  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {willFuneralHelpers} from "./helpers/willFuneralHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import FuneralType from "../../question/questions/will/funeral/FuneralType";
import DonorType from "../../question/questions/will/funeral/DonorType";
import CustomWishes from "../../question/questions/will/funeral/CustomWishes";
import StyleType from "../../question/questions/will/funeral/StyleType";
import CustomWishesYN from "../../question/questions/will/funeral/CustomWishesYN";


export default {
  name: 'Funeral',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willFuneralHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CustomWishesYN,
    StyleType,
    CustomWishes,
    DonorType,
    FuneralType

  },
  data() {
    return {
      label: 'Funeral', // used for steps progress
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
      db: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.funeral.details'
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
