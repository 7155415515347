import {willPeopleHelpers} from "./willPeopleHelpers";
import {willRelationshipHelpers} from "./willRelationshipHelpers";
import {willProfessionalOptions} from "./willProfessionalOptions";


export const willExecutorsHelpers = {
    // required a details computed property: only to be used in root level Executors file
    mixins: [willPeopleHelpers, willRelationshipHelpers, willProfessionalOptions],
    mounted() {
        // calculate people options
        this.setOptionsData()
        let attributeAdded = false
        // for old created will insert new fields into data
        if (!('typeOfExecutors' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'typeOfExecutors', null)
            attributeAdded = true
        }
        if (!('secondaryYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'secondaryYN', null)
            attributeAdded = true
        }
        if (!('backupYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'backupYN', null)
            attributeAdded = true
        }
        if (attributeAdded) this.save()
    },
    watch: {
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        details: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        }
    },
    computed: {
        professionalsOnly() {
            return this.details.typeOfExecutors === 'professional'
        },
        friendsAndProfessionals() {
            return this.details.typeOfExecutors === 'friends_family_professionals'
        },
        friendsOnly() {
            return this.details.typeOfExecutors === 'friends_family'
        },
        secondaryRequired() {
            return this.details.secondaryYN
        },
        backupRequired() {
            return this.details.backupYN
        },
        storePeople() {
            return this.$store.getters.people
        },
        details() {
            return this.form.data.executors.details
        },
        data() {
            return this.form.data.executors.details
        },
    },
    methods: {
        async syncOptions() {
            this.clearAllExecutors()
            await this.addPartner()
            await this.addProfessional()
            this.save()
        },
        async addPartner() {
            // add partner to primary executors
            if (this.partner && (this.friendsAndProfessionals || this.friendsOnly)) {
                // find partner in primary executors
                let partnerPrimaryIndex = this.details.primary.findIndex(client => client.id === this.partner.id)
                if (partnerPrimaryIndex >= 0 && !this.details.primaryPartner) {
                    // remove partner
                    this.details.primary.splice(partnerPrimaryIndex, 1)
                    return true
                } else if (this.details.primaryPartner) {
                    // add partner
                    this.details.primary.push(this.convertPerson(this.partner))
                    // remove partner from secondary and backup
                    let partnerSecondaryIndex = this.details.secondary.findIndex(client => client.id === this.partner.id)
                    let partnerBackupIndex = this.details.backup.findIndex(client => client.id === this.partner.id)
                    if (partnerSecondaryIndex >= 0) this.details.secondary.splice(partnerSecondaryIndex, 1)
                    if (partnerBackupIndex >= 0) this.details.backup.splice(partnerBackupIndex, 1)
                    return true
                }
            } else {
                return false
            }
        },
        async addProfessional() {
            // add professional executor depending on type of executors selected
            if (this.professionalsOnly || this.friendsAndProfessionals) await this.addProfessionalExecutor()
            return true
        },
        async addProfessionalExecutor() {
            this.details.primary.push(this.professionalDunham)
            return true
        },
        clearAllExecutors() {
            this.details.primary = []
            this.details.secondary = []
            this.details.backup = []
        },
        clearSecondary() {
            if (this.details.secondaryYN !== false) return
            this.details.secondary = []
            this.save()
        },
        clearBackup() {
            if (this.details.backupYN !== false) return
            this.details.backup = []
            this.save()
        },
        setOptionsData() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []
            let serverSecondary = []
            let serverBackup = []

            // convert people
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfExecutors in final form for will
                if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            })

            // add professional if type of executors selected by user includes professional
            if (this.details.typeOfExecutors && this.details.typeOfExecutors.includes('professional')) {
                serverPeople.unshift(this.professionalDunham)
            }

            // filter out primary, secondary or backup options
            let primaryIds = this.details.primary.map(person => person.id)
            let secondaryIds = this.details.secondary.map(person => person.id)
            let backupIds = this.details.backup.map(person => person.id)
            serverPrimary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))
            serverSecondary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))
            serverBackup = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))

            // return
            this.primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
            this.secondaryOptions = [...serverSecondary].sort((a, b) => a.id - b.id)
            this.backupOptions = [...serverBackup].sort((a, b) => a.id - b.id)
        }
    }
}
