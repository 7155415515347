<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :options="booleanYesNo"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {general} from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

export default {
  name: 'SecondaryYN',
  mixins: [
    general,
    questionLoadHelpers,
    willPeopleHelpers,
    willRelationshipHelpers,
    personHelpers,
    textHelpers
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    details: {
      type: Object,
      required: false
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: 'Would you like to name secondary executors?',
        subTitle: `You should plan for every eventuality and name people who can step in if ${this.listPeopleAddressDob({
          people: this.details.primary,
          noDob: true
        })} ${this.isAre({list: this.details.primary})} unable or un-willing to act.`,
        tip: null,
      }
    },
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: 'secondaryYNExecutors',
        formPath: 'data.executors.details.secondaryYN',
        jsonSaveField: 'secondaryYN',
        callbackFunction: this.callbackFunction
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
