<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Secondary Distribution</h3>

    <ChildrenSecondaryYN v-if="partner && dataPrimary.partnerPrimaryYN && children.length"
                         v-model="form.data.distributionSecondary.details.childrenSecondaryYN"
                         :callback-function="handleChildrenAsSecondary"/>

    <template
        v-if="!(partner && dataPrimary.partnerPrimaryYN && children.length) || form.data.distributionSecondary.details.childrenSecondaryYN !== null">
      <transition name="fade">
        <ShareObjects
            v-model="form.data.distributionSecondary.details.shares"
            :details="dataSecond"
            :optionsData="secondaryOptions"
            :title="secondaryQuestionTitle"
            button-text="Add Secondary Beneficiary"
            :show-button="true"
            location="distributionSecondary"/>
      </transition>

      <div v-for="(share, index) in dataSecond.shares"
           :key="'distributionSecondaryBackupShare' + index">
        <template
            v-if="!(share.recipients.length === 1 && share.recipients.filter(person => person.type === 'charity').length === 1)">

          <ShareStirpes
              v-model="form.data.distributionSecondary.details.shares[index].stirpes"
              :index="index"
              :share="share"
              location="distributionSecondary"/>

          <div
              v-if="dataSecond.shares[index].stirpes || ((!share.recipients.filter(person => person.type === 'group').length && dataSecond.shares.length > 1) && dataSecond.shares[index].stirpes !== null || dataSecond.shares[index].backupsYN)"
              class="question-box with-arrow">
            <ShareStirpesAge
                v-if="dataSecond.shares[index].stirpes"
                v-model="form.data.distributionSecondary.details.shares[index].stirpesAge"
                :index="index"
                :share="share"
                location="distributionSecondary"/>

            <template
                v-if="!share.recipients.filter(person => person.type === 'group').length && dataSecond.shares.length > 1">
              <ShareBackupYN
                  v-if="dataSecond.shares[index].stirpes !== null"
                  v-model="form.data.distributionSecondary.details.shares[index].backupsYN"
                  :callback-function="handleBackupShare"
                  :index="index"
                  :share="share"
                  :shares="dataSecond.shares"
                  location="distributionSecondary"/>

              <template v-if="dataSecond.shares[index].backupsYN">
                <ShareBackupRecipients
                    v-for="(person, i) in dataSecond.shares[index].recipients"
                    :key="`share${index}BackupPerson${i}`"
                    v-model="form.data.distributionSecondary.details.shares[index].recipients[i].recipients"
                    :details="dataSecond.shares[index].recipients[i]"
                    :optionsData="secondaryOptions.filter(option => option.id !== dataSecond.shares[index].recipients[i].id)"
                    :recipientIndex="i"
                    :shareIndex="index"
                    location="distributionSecondary"/>
              </template>
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willDistributionHelpers} from "./helpers/willDistributionHelpers";
import {willRelationshipHelpers} from "./helpers/willRelationshipHelpers";
import {personHelpers} from "@/mixins/personHelpers";

import ShareObjects from "../../question/questions/will/distribution/ShareObjects";
import ShareBackupRecipients from "../../question/questions/will/distribution/ShareBackupRecipients";
import ShareStirpes from "../../question/questions/will/distribution/ShareStirpes";
import ShareBackupYN from "../../question/questions/will/distribution/ShareBackupYN";
import ShareStirpesAge from "../../question/questions/will/distribution/ShareStirpesAge";
import ChildrenSecondaryYN
  from "@/components/common/questionnaires/question/questions/will/distribution/ChildrenSecondaryYN";

export default {
  name: 'DistributionSecondary',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willDistributionHelpers,
    willRelationshipHelpers,
    personHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    ChildrenSecondaryYN,
    ShareStirpesAge,
    ShareBackupYN,
    ShareStirpes,
    ShareBackupRecipients,
    ShareObjects
  },
  data() {
    return {
      label: 'Secondary', // used for steps progress
      dbPrimary: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.distributionPrimary.details'
      },
      dbSecond: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.distributionSecondary.details'
      },
      dbBackup: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.distributionBackup.details'
      },
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    people(share) {
      return this.listPeopleAddressDob({
        people: share.recipients,
        firstName: true,
        noDob: true
      })
    }
  }
};
</script>
