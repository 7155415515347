<template>
  <div>
<!--    <div class="question-box-intro-two"><h3>Share Details</h3><h3></h3></div>-->

    <SharePercentage
        v-model="form.share"
        :index="index"
        :no-save="noSave"
        :errors-post="errorsPost.share"
        :location="location"
        :id="`${location}${index}share`"
    />
<!--RECIPIENTS REMOVE FOR SIMPLICITY: DELETE SHARE THEN RECREATE IF NEEDED-->
<!--    <Recipients-->
<!--        v-model="form.recipients"-->
<!--        :options-data="optionsData"-->
<!--        :index="index"-->
<!--        :no-save="noSave"-->
<!--        :location="location"-->
<!--        :errors-post="errorsPost.recipients"-->
<!--        :id="`${location}${index}recipients`"-->
<!--    />-->

  </div>
</template>

<script>
import { shareRecipientHelpers } from "../../../question/questions/will/distribution/shareRecipientHelpers";

import SharePercentage from "../../../question/questions/will/distribution/sub/SharePercentage";
// import Recipients from "../../../question/questions/will/distribution/sub/Recipients";


export default {
  name: 'Share',
  components: {
    // Recipients,
    SharePercentage
  },
  mixins: [shareRecipientHelpers],
  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    },
    location: {
      type: String,
      required: true
    },
    optionsData: {
      type: Array,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>