<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Backup Distribution</h3>

    <BackupYN v-model="form.data.distributionBackup.details.backupYN"
              :callback-function="handleBackup"/>

    <template v-if="form.data.distributionBackup.details.backupYN">
      <transition name="fade">
        <ShareObjects
            v-model="form.data.distributionBackup.details.shares"
            :details="dataBackup"
            :optionsData="backupOptions"
            :title="backupQuestionTitle"
            :showButton="true"
            button-text="Add Backup Beneficiary"
            location="distributionBackup"/>
      </transition>

      <div v-for="(share, index) in dataBackup.shares"
           :key="'distributionBackupBackupShare' + index">

        <template
            v-if="!(share.recipients.length === 1 && share.recipients.filter(person => person.type === 'charity').length === 1)">

          <ShareStirpes
              v-model="form.data.distributionBackup.details.shares[index].stirpes"
              :index="index"
              :share="share"
              location="distributionBackup"/>

          <div v-if="dataBackup.shares[index].stirpes" class="question-box with-arrow">
            <ShareStirpesAge
                v-if="dataBackup.shares[index].stirpes"
                v-model="form.data.distributionBackup.details.shares[index].stirpesAge"
                :index="index"
                :share="share"
                location="distributionBackup"/>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willDistributionHelpers} from "./helpers/willDistributionHelpers";
import {willRelationshipHelpers} from "./helpers/willRelationshipHelpers";
import {personHelpers} from "@/mixins/personHelpers";

import ShareObjects from "../../question/questions/will/distribution/ShareObjects";
import ShareStirpes from "../../question/questions/will/distribution/ShareStirpes";
import ShareStirpesAge from "../../question/questions/will/distribution/ShareStirpesAge";
import BackupYN from "../../question/questions/will/distribution/BackupYN";

export default {
  name: 'DistributionBackup',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willDistributionHelpers,
    willRelationshipHelpers,
    personHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    BackupYN,
    ShareStirpesAge,
    ShareStirpes,
    ShareObjects
  },
  data() {
    return {
      label: 'Backup', // used for steps progress
      dbPrimary: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.distributionPrimary.details'
      },
      dbSecond: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.distributionSecondary.details'
      },
      dbBackup: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.distributionBackup.details'
      },
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    people(share) {
      return this.listPeopleAddressDob({
        people: share.recipients,
        firstName: true,
        noDob: true
      })
    }
  }
};
</script>
