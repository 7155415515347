<template>
  <QuestionBase
    :id="db.saveField"
    :errors="[...jsErrors]"
    :question="question"

    :valid="!!data.length"
  >
    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard
          v-for="(person, index) in data"
          :id="
            person.type === 'professional'
              ? 'executoryPrimaryDataProfessional'
              : 'executorPrimaryData' + index
          "
          :key="
            person.type === 'professional'
              ? 'executoryPrimaryDataProfessional'
              : 'executorPrimaryData' + person.id
          "
          :ref="
            person.type === 'professional'
              ? 'executoryPrimaryDataProfessional'
              : 'executorPrimaryData' + index
          "
          :select-mode="true"
          :index="index"
          :selected="true"
          :title="cardTitle(person)"
          :value="data[index]"
          :show-delete="false"
          type="executorPrimaryData"
          :loading="loading"
          @click="
            professionalsOnly
              ? null
              : deselectConfirm(
                  person,
                  'Remove person from your primary Executors?'
                )
          "
          @delete="deletePerson(person.id)"
          @save="
            savePerson(
              null,
              person.type === 'professional'
                ? 'executoryPrimaryDataProfessional'
                : 'executorPrimaryData' + index
            )
          "
        >
          <PersonWill
            v-if="person.type === 'person'"
            v-model="data[index]"
            :key="'executorPrimary-form-data' + person.id"
            :errors-post="errorsPatch"
            :no-save="true"
            :objectId="person.id"
            @save="savePersonForm($event, person)"
          />

          <ProfessionalWill
            v-else-if="person.type === 'professional'"
            :key="'executorPrimary-form-data-professional' + index"
            v-model="data[index]"
            @save="savePersonForm($event, person)"
          />
        </ObjectCard>
      </transition-group>
    </div>

    <!-- New People -->
    <transition name="fade">
      <template
        v-if="
          (!details.primaryPartner && !professionalsOnly) ||
          (!partner && !professionalsOnly)
        "
      >
        <b-button
          v-if="!professionalsOnly"
          class="btn-question w-100"
          @click="show.addPerson = true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Executor
        </b-button>
      </template>
    </transition>

    <WillPeopleModal
      v-model="show.addPerson"
      title="Add Primary Executors"
      :options="optionsData"
      :dataSelected="data"
      :sub-title="'Select your executors by selecting existing people or adding new people. You can select multiple individuals for this role.'"
      :hide-type-options="true"
      :show-charities="false"
      :show-groups="false"
      :add-person-function="addPersonFunction"
      @dataSelectedUpdate="data = $event"
      @save="save"
    />
  </QuestionBase>
</template>

<script>
import { personHelpers } from "@/mixins/personHelpers";
import { peopleSaveHelpers } from "../../objects/peopleSaveHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";
import { willPeopleObjectHelpers } from "../helpers/willPeopleObjectHelpers";
import { willPeopleHelpers } from "../../../../fieldsets/will/helpers/willPeopleHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PersonWill from "../../../../fieldsets/sub/people/PersonWill";
import ProfessionalWill from "../../../../fieldsets/sub/people/ProfessionalWill";
import WillPeopleModal from "../helpers/WillPeopleModal";

export default {
  name: "PrimaryExecutors",
  components: {
    WillPeopleModal,
    PersonWill,
    ProfessionalWill,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers,
    willPeopleHelpers,
  ],
  props: {
    value: {
      type: Array,
      required: false,
    },
    details: {
      type: Object,
      required: true,
    },
    optionsData: {
      type: Array,
      required: true,
    },
    addPersonFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: "product_will_data",
        saveField: "primaryExecutors",
        formPath: "data.executors.details.primary",
        jsonSaveField: "primary",
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage:
          "You must have more than one Primary Executor, click to select",
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false,
      },
    };
  },
  computed: {
    question() {
      return {
        title: this.details.primaryPartner || this.professionalsOnly ? (this.data && this.data.length === 1 ? "Primary Executor" : "Primary Executors") : "Who would you like to deal with the administration of your estate?",
        subTitle:
          this.details.primaryPartner || this.professionalsOnly
            ? null
            : "Please confirm who you would like to act as the primary executors. To deselect a person, simply click on their name.",
        tip: null,
      };
    },
    data: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    professionalsOnly() {
      return this.details.typeOfExecutors === "professional";
    },
  },
};
</script>
