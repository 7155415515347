<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Executors</h3>

    <TypeOfExecutors
        v-model="form.data.executors.details.typeOfExecutors"
        :details="form.data.executors.details"
        :callbackFunction="syncOptions"
    />

    <PartnerPrimaryYN
        v-if="(friendsAndProfessionals || friendsOnly) && partner"
        v-model="form.data.executors.details.primaryPartner"
        :callbackFunction="syncOptions"
    />

    <template v-if="([true, false].includes(details.primaryPartner) || !partner || professionalsOnly)">
      <div
          v-if="details.typeOfExecutors"
          class="question-box with-arrow">
        <PrimaryExecutors
            v-model="form.data.executors.details.primary"
            :details="form.data.executors.details"
            :optionsData="primaryOptions"
            :add-person-function="addUnderAgeCondition"
        />
      </div>

      <SecondaryYN
          v-if="(friendsAndProfessionals || friendsOnly) && (details.primary.length || details.secondary.length)"
          v-model="form.data.executors.details.secondaryYN"
          :details="form.data.executors.details"
          :callbackFunction="clearSecondary"
      />

      <template v-if="(friendsAndProfessionals || friendsOnly) && secondaryRequired">

        <div v-if="details.primary.length || details.secondary.length"
             class="question-box with-arrow">

          <SecondaryExecutors
              v-model="form.data.executors.details.secondary"
              :details="form.data.executors.details"
              :optionsData="secondaryOptions"
              :add-person-function="addUnderAgeCondition"
          />
        </div>
      </template>

      <BackupYN
          v-if="details.secondary.length || details.backup.length"
          v-model="form.data.executors.details.backupYN"
          :details="form.data.executors.details"
          :callbackFunction="clearBackup"
      />

      <template v-if="(friendsAndProfessionals || friendsOnly) && backupRequired">
        <div v-if="details.secondary.length || details.backup.length"
             class="question-box with-arrow">
          <BackupExecutors
              v-model="form.data.executors.details.backup"
              :details="form.data.executors.details"
              :optionsData="secondaryOptions"
              :add-person-function="addUnderAgeCondition"
          />
        </div>
      </template>

    </template>

  </div>
</template>

<script>
import {willPeopleHelpers} from "./helpers/willPeopleHelpers";
import {fieldsetHelpers} from "../fieldsetHelpers";
import {willExecutorsHelpers} from "./helpers/willExecutorsHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willConditionHelpers} from "@/components/common/questionnaires/fieldsets/will/helpers/willConditionHelpers";

import PartnerPrimaryYN from "../../question/questions/will/executors/PartnerPrimaryYN";
import PrimaryExecutors from "../../question/questions/will/executors/PrimaryExecutors";
import SecondaryExecutors from "../../question/questions/will/executors/SececondaryExecutors";
import BackupExecutors from "../../question/questions/will/executors/BackupExecutors";
import TypeOfExecutors from "../../question/questions/will/executors/TypeOfExecutors";
import SecondaryYN from "../../question/questions/will/executors/SecondaryYN";
import BackupYN from "../../question/questions/will/executors/BackupYN";

export default {
  name: 'Executors',
  mixins: [
    fieldsetHelpers,
    willExecutorsHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willConditionHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    BackupYN,
    SecondaryYN,
    TypeOfExecutors,
    BackupExecutors,
    SecondaryExecutors,
    PrimaryExecutors,
    PartnerPrimaryYN
  },
  mounted() {
    // refresh people on mount if will instruction has children section
    this.$store.dispatch('peopleFetch')
  },
  data() {
    return {
      label: 'Executors', // used for steps progress
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
      db: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.executors.details'
      },
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
