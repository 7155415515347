var render = function render(){var _vm=this,_c=_vm._self._c;return _c('QuestionBase',{attrs:{"id":_vm.db.saveField,"errors":[..._vm.jsErrors],"question":_vm.question,"valid":!!_vm.data.length}},[_c('div',{staticClass:"accordion sub-items"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.data),function(person,index){return _c('ObjectCard',{key:person.type === 'professional'
            ? 'executoryPrimaryDataProfessional'
            : 'executorPrimaryData' + person.id,ref:person.type === 'professional'
            ? 'executoryPrimaryDataProfessional'
            : 'executorPrimaryData' + index,refInFor:true,attrs:{"id":person.type === 'professional'
            ? 'executoryPrimaryDataProfessional'
            : 'executorPrimaryData' + index,"select-mode":true,"index":index,"selected":true,"title":_vm.cardTitle(person),"value":_vm.data[index],"show-delete":false,"type":"executorPrimaryData","loading":_vm.loading},on:{"click":function($event){_vm.professionalsOnly
            ? null
            : _vm.deselectConfirm(
                person,
                'Remove person from your primary Executors?'
              )},"delete":function($event){return _vm.deletePerson(person.id)},"save":function($event){return _vm.savePerson(
            null,
            person.type === 'professional'
              ? 'executoryPrimaryDataProfessional'
              : 'executorPrimaryData' + index
          )}}},[(person.type === 'person')?_c('PersonWill',{key:'executorPrimary-form-data' + person.id,attrs:{"errors-post":_vm.errorsPatch,"no-save":true,"objectId":person.id},on:{"save":function($event){return _vm.savePersonForm($event, person)}},model:{value:(_vm.data[index]),callback:function ($$v) {_vm.$set(_vm.data, index, $$v)},expression:"data[index]"}}):(person.type === 'professional')?_c('ProfessionalWill',{key:'executorPrimary-form-data-professional' + index,on:{"save":function($event){return _vm.savePersonForm($event, person)}},model:{value:(_vm.data[index]),callback:function ($$v) {_vm.$set(_vm.data, index, $$v)},expression:"data[index]"}}):_vm._e()],1)}),1)],1),_c('transition',{attrs:{"name":"fade"}},[(
        (!_vm.details.primaryPartner && !_vm.professionalsOnly) ||
        (!_vm.partner && !_vm.professionalsOnly)
      )?[(!_vm.professionalsOnly)?_c('b-button',{staticClass:"btn-question w-100",on:{"click":function($event){_vm.show.addPerson = true}}},[_c('i',{staticClass:"i-Add text-25 font-weight-800 mr-2"}),_vm._v(" Add Executor ")]):_vm._e()]:_vm._e()],2),_c('WillPeopleModal',{attrs:{"title":"Add Primary Executors","options":_vm.optionsData,"dataSelected":_vm.data,"sub-title":'Select your executors by selecting existing people or adding new people. You can select multiple individuals for this role.',"hide-type-options":true,"show-charities":false,"show-groups":false,"add-person-function":_vm.addPersonFunction},on:{"dataSelectedUpdate":function($event){_vm.data = $event},"save":_vm.save},model:{value:(_vm.show.addPerson),callback:function ($$v) {_vm.$set(_vm.show, "addPerson", $$v)},expression:"show.addPerson"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }