<template>
  <QuestionBase
      :question="question"
      :errors="[...jsErrors]"
      :valid="!!data"
  >
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
    />
  </QuestionBase>
</template>

<script>
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {textHelpers} from "@/mixins/textHelpers";
import {personHelpers} from "@/mixins/personHelpers";
import {shareSaveHelpers} from "./shareSaveHelpers";

import QuestionBase from "../../../QuestionBase";
import InputBasic from "../../../inputs/InputBasic";

export default {
  name: 'ShareStirpesAge',
  components: {
    InputBasic,
    QuestionBase
  },
  mixins: [questionLoadHelpers, blankForms, textHelpers, shareSaveHelpers, saveHelpers, personHelpers],
  props: {
    value: {
      type: String,
      required: false
    },
    location: {
      type: String,
      required: true
    },
    share: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}Shares${this.index}StirpesAge`,
        formPath: `data.${this.location}.details.shares.${this.index}.stirpesAge`,
        jsonSaveField: 'stirpesAge',
      },
      args: {
        placeholder: 'e.g. 18',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-33',
        prependText: 'Age'
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      sharesMainInput: [],
      addShareYN: null,
      show: {
        addPerson: false
      },
    }
  },
  computed: {
    question() {
      let questionTitle = ''

      if (this.multipleRecipients) {
        questionTitle = `At what age should their children inherit?`
      } else if (this.soleRecipient) {
        questionTitle = `If ${this.heShe(this.soleRecipient.gender)} has a child or children who are younger at the time, at what age should they inherit?`
      }

      return {
        title: questionTitle,
        subTitle: null,
        tip: null,
      }
    },
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    hasGroupInRecipients() {
      return !!this.share.recipients.filter(option => option.type === 'group').length
    },
    multipleRecipients() {
      return this.share.recipients.length > 1 || this.hasGroupInRecipients
    },
    soleRecipient() {
      if (this.share.recipients.length === 1) {
        return this.share.recipients[0]
      }
      return false
    }
  }
}
</script>
