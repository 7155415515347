<template>
  <QuestionBase :id="db.saveField"

                :errors="[...jsErrors]"
                :question="question"
                :valid="!!data.length">

    <!-- Selected People -->
    <div class="accordion sub-items">
      <transition-group name="fade">
        <ObjectCard v-for="(person, index) in data"
                    :id="'executorBackupData' + index"
                    :key="'executorBackupData' + person.id"
                    :ref="'executorBackupData' + index"
                    :select-mode="true"
                    :selected="true"
                    :show-delete="false"
                    :title="cardTitle(person)"
                    :value="data[index]"
                    type="executorBackupData"
                    :loading="loading"
                    @click="deselectConfirm(person, 'Remove person from your backup Executors?')"
                    @delete="deletePerson(person.id)"
                    @save="savePerson( null, 'executorBackupData'+index)">

          <PersonWill v-if="person.type === 'person'"
                      :key="'executorBackup-form-data' + person.id"
                      v-model="data[index]"
                      :errors-post="errorsPatch"
                      :no-save="true"
                      :objectId="person.id"
                      @save="savePersonForm($event, person)" />

          <ProfessionalWill v-else-if="person.type === 'professional'"
                            :key="'executorBackup-form-data-professional' + index"
                            v-model="data[index]" />

        </ObjectCard>
      </transition-group>
    </div>
    <!-- New People -->
    <transition name="fade">
      <b-button class="btn-question w-100"
                @click="show.addPerson=true">
        <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Executor
      </b-button>
    </transition>

    <WillPeopleModal
        v-model="show.addPerson"
        title="Add Backup Executors"
        :dataSelected="data"
        :options="optionsData"
        :show-charities="false"
        :show-groups="false"
        :hide-type-options="true"
        :sub-title="'Select your backup executors by selecting existing people or adding new people. You can select multiple individuals for this role.'"
        :add-person-function="addPersonFunction"
        @dataSelectedUpdate="data=$event"
        @save="save" />

  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willPeopleObjectHelpers} from "../helpers/willPeopleObjectHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PersonWill from "../../../../fieldsets/sub/people/PersonWill";
import ProfessionalWill from "../../../../fieldsets/sub/people/ProfessionalWill";
import WillPeopleModal from "../helpers/WillPeopleModal";

export default {
  name: 'BackupExecutors',
  components: {
    WillPeopleModal,
    PersonWill,
    ProfessionalWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    details: {
      type: Object,
      required: true
    },
    optionsData: {
      type: Array,
      required: true
    },
    addPersonFunction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      question: {
        title: 'Backup Executors',
        subTitle: 'Please confirm who you would like to act as the backup executors. To deselect a person, simply click on their name.',
        tip: null
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: 'backupExecutors',
        formPath: 'data.executors.details.backup',
        jsonSaveField: 'backup'
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must have more than one Backup Executor, click to select'
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
