<template>
  <div>
    <FullName
        :noSave="noSave"
        :objectId="objectId"
        :value-first="form.name_first"
        :value-middle="form.name_middle"
        :value-last="form.name_last"
        :errors-post-first="errorsPost.name_first || []"
        :errors-post-middle="errorsPost.name_middle || []"
        :errors-post-last="errorsPost.name_last || []"
        @inputFirst="form.name_first=$event"
        @inputMiddle="form.name_middle=$event"
        @inputLast="form.name_last=$event"
    />

    <Relationship
        v-model="form.relationship_to_client"
        :no-save="noSave"
        :errors-post="errorsPost.relationship_to_client || []"
        :objectId="objectId"
    />

    <Dob
        v-model="form.dob"
        :noSave="noSave"
        :errors-post="errorsPost.dob || []"
        :objectId="objectId"
    />

    <Gender
        v-model="form.gender"
        :noSave="noSave"
        :objectId="objectId"
        :errors-post="errorsPost.gender || []"
    />

    <DependantUntil
        v-model="form.dependant_until"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.dependant_until || []"
    />

    <Email
        v-model="form.email"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.email || []"
    />
    <Phone
        v-model="form.phone"
        :no-save="noSave"
        :object-id="objectId"
        :errors-post="errorsPost.phone || []"
    />

    <AddressPerson
        v-model="form.address"
        :form="form"
        :errors-post="errorsPost.address || []"
    />

  </div>
</template>

<script>
import FullName from "../../../question/questions/clientPerson/FullName";
import Dob from "../../../question/questions/clientPerson/Dob";
import Relationship from "../../../question/questions/clientPerson/Relationship";
import Gender from "../../../question/questions/clientPerson/Gender";
import DependantUntil from "../../../question/questions/clientPerson/DependantUntil";
import Email from "../../../question/questions/clientPerson/Email";
import Phone from "../../../question/questions/clientPerson/Phone";
import AddressPerson from "../../../question/questions/clientPerson/AddressPerson";

export default {
  name: 'DependantFull',
  components: {AddressPerson, Phone, Email, DependantUntil, Gender, Relationship, Dob, FullName},
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>