<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {dateFormat} from "@/mixins/dateFormat";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import NameStatusFull from "../../../components/common/questionnaires/fieldsets/full/NameStatusFull";
import ChildrenDependantsFull from "../../../components/common/questionnaires/fieldsets/full/ChildrenDependantsFull";
import AssetsFull from "../../../components/common/questionnaires/fieldsets/full/AssetsFull";
import LiabilitiesFull from "../../../components/common/questionnaires/fieldsets/full/LiabilitiesFull";
import AddressFull from "../../../components/common/questionnaires/fieldsets/full/AddressFull";
import Executors from "../../../components/common/questionnaires/fieldsets/will/Executors";
import Trustees from "../../../components/common/questionnaires/fieldsets/will/Trustees";
import Guardians from "../../../components/common/questionnaires/fieldsets/will/Guardians";
import Pets from "../../../components/common/questionnaires/fieldsets/will/Pets";
import Funeral from "../../../components/common/questionnaires/fieldsets/will/Funeral";
import Gifts from "../../../components/common/questionnaires/fieldsets/will/Gifts";
import DistributionPrimary from "@/components/common/questionnaires/fieldsets/will/DistributionPrimary";
import DistributionSecondary from "@/components/common/questionnaires/fieldsets/will/DistributionSecondary";
import DistributionBackup from "@/components/common/questionnaires/fieldsets/will/DistributionBackup";
import HealthStatus from "@/components/common/questionnaires/fieldsets/estatePlanningSurvey/HealthStatus";
import ExistingProducts from "@/components/common/questionnaires/fieldsets/estatePlanningSurvey/ExistingProducts";
import Insurance from "@/components/common/questionnaires/fieldsets/estatePlanningSurvey/Insurance";
import Business from "@/components/common/questionnaires/fieldsets/estatePlanningSurvey/Business";
import Distribution from "@/components/common/questionnaires/fieldsets/estatePlanningSurvey/Distribution";
import Divorce from "@/components/common/questionnaires/fieldsets/estatePlanningSurvey/Divorce";
import {httpQuestionnaire} from "@/services";

export default {
  name: "FactFindEstatePlanningWill",
  mixins: [loadHelpers, dateFormat],
  components: {
    QuestionnaireBase,
    NameStatusFull,
    ChildrenDependantsFull,
    AssetsFull,
    LiabilitiesFull,
    AddressFull,
    HealthStatus,
    ExistingProducts,
    Insurance,
    Business,
    Distribution,
    Divorce,
    Executors,
    Trustees,
    Guardians,
    Pets,
    Funeral,
    Gifts,
    DistributionPrimary,
    DistributionSecondary,
    DistributionBackup
  },
  data() {
    return {
      name: 'WillFull',
      title: "Fact Find, Estate Planning & Will Instructions",
      url: 'fact_find_estate_planning_will',
      order:
          [
            'NameStatusFull',
            'AddressFull',
            'ChildrenDependantsFull',
            'AssetsFull',
            'LiabilitiesFull',
            'HealthStatus',
            'Divorce',
            'ExistingProducts',
            'Business',
            'Distribution',
            'Insurance',
            'Executors',
            'Trustees',
            'Guardians',
            'Gifts',
            'DistributionPrimary',
            'DistributionSecondary',
            'DistributionBackup',
            'Pets',
            'Funeral'
          ],
      finishFunction: this.instructionsTaken,
      expiryAccessCodeOnFinish: true
    }
  },
  methods: {
    async instructionsTaken() {
      return httpQuestionnaire
          .patch(this.url, {
            will_id: this.$route.params.willId,
            status: "awaiting_checking",
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async handover() {
      // TODO handover client on completion??
    }
  }
};
</script>
