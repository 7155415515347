<template>
  <QuestionBase
      :question="question"
      :errors="[...jsErrors]"
      :valid="!!data.length"

      :id="db.saveField"
  >
    <div class="question-box with-arrow mt-32">
      <transition name="fade">

        <template>
          <div class="accordion sub-items" >
            <ObjectCard
                v-for="(share, index) in data"
                v-model="data[index]"
                :key="'gift' + index" :id="`${location}${index}`"
                :ref="`${location}${index}`"
                type="gift"
                :loading="loading"
                :title="shareTitle(data[index])"
                :index="index"
                :show-delete="('partnerPrimaryYN' in details && details.partnerPrimaryYN === false) || !('partnerPrimaryYN' in details)"
                :show-save="('partnerPrimaryYN' in details && details.partnerPrimaryYN === false) || !('partnerPrimaryYN' in details)"
                @save="saveShare(data[index], index, `${location}${index}`)"
                @delete="deleteShareConfirm(index)"
            >

              <Share
                  v-model="data[index]"
                  :errors-post="errorsPost"
                  :index="index"
                  :location="location"
                  :optionsData="optionsData"
                  @saveShare="saveShare(data[index], index, null)"
              />

            </ObjectCard>
            <transition  name="fade">


              <b-button
                  v-show="('partnerPrimaryYN' in details && details.partnerPrimaryYN === false) || !partner || showButton"
                  class="btn-question w-100"
                  @click="show.addPerson=true"
              >
                <i class="i-Add text-25 font-weight-800 mr-2"> </i> {{ buttonText }}
              </b-button>
            </transition>
          </div>

        </template>
      </transition>
    </div>

    <WillPeopleModal
        v-model="show.addPerson"
        :options="optionsData"
        :dataSelected="[]"
        :show-charities="true"
        :show-groups="true"
        :noSave="true"
        title="What type of beneficiary would you like to add?"
        sub-title="A single person or multiple people can be selected. You can also add groups of people such as 'Grandchildren'."
        @dataSelectedUpdate="addShareWithPeople"
        @save="save"
        @added="addShareWithPeople"
    />

  </QuestionBase>
</template>

<script>
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {assetOptions} from "../../../options/assetOptions";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {general} from "../../../options/general";
import {textHelpers} from "@/mixins/textHelpers";
import { personHelpers } from "@/mixins/personHelpers";
import { currencyFormat } from "@/mixins/currencyFormat";
import {shareSaveHelpers} from "./shareSaveHelpers";
import { numberFormat } from "@/mixins/numberFormat";
import { willPeopleHelpers } from "../../../../fieldsets/will/helpers/willPeopleHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import Share from "../../../../fieldsets/sub/distribution/Share";
import WillPeopleModal from "../helpers/WillPeopleModal";

export default {
  name: 'ShareObjects',
  components: {WillPeopleModal, Share, ObjectCard, QuestionBase},
  mixins: [willPeopleHelpers, numberFormat, questionLoadHelpers, assetOptions, blankForms, general, textHelpers, shareSaveHelpers, saveHelpers, personHelpers, currencyFormat],
  props: {
    value: {
      type: Array,
      required: false
    },
    location: {
      type: String,
      required: true
    },
    optionsData: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    details: {
      type: Object,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    title: {
      deep: false,
      immediate: false,
      handler(value) {
        this.question.title = value
      }
    }
  },
  data() {
    return {
      question: {
        title: this.title,
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: this.location,
        formPath: `data.${this.location}.details.shares`,
        jsonSaveField: 'shares',
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessageNewOpenForm: "Save share before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      sharesMainInput: [],
      addShareYN: null,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    selectedPeople () {
      return this.data.reduce((result, share) => share.recipients.map(person => person).concat(result), [])
    }
  },
  methods: {
    shareTitle(share) {

      return `${this.capitalise(share.share)} to ${this.listPeopleAddressDob({people: share.recipients, noDob: true, firstName: true})}`
    },
    addShareWithPeople (data) {
      // create new share
      let newShare = this.clone(this.distributionBlankShareForm)
      // add new people/groups/charities to recipients of new share
      for (let i = 0; i < data.length; i++) {
        newShare.recipients.push(data[i])
      }
      // add new share to distribution
      this.data.push(newShare)
      // auto calculate percentages of all shares
      let percentage = this.roundToTwo(
          Number(100 / this.data.length)
      );
      for (let i in this.data) {
        this.data[i].share = String(percentage + '%');
      }
      this.save()
    }
  }
}
</script>
