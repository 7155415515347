<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">
    <transition name="fade">
      <div class="accordion sub-items"
           >

        <ObjectCard v-for="(person, index) in data"
                    :id="'child'+index"
                    :key="'child' + person.id"
                    :ref="'child'+index"
                    v-model="data[index]"
                    :title="`${fullName(person)} (${ person.relationship_to_client})`"
                    type="child"
                    :loading="loading"
                    @delete="deleteObject(person.id)"
                    @save="saveObject(person, 'child'+index, null, null, person.id)">

          <ChildFull :key="'child-form' + person.id"
                     v-model="data[index]"
                     :objectId="person.id"
                     :errors-post="errorsPost"
          />
        </ObjectCard>

        <ObjectCard v-if="form"
                    ref="child-add"
                    v-model="form"
                    title="New Child"
                    type="child-add"
                    :loading="loading"
                    @delete="form=null"
                    @save="saveObject(form, db.saveField, null, null, 'new-child')">
          <ChildFull v-model="form"
                     :errors-post="errorsPost"
                     :noSave="true"
                     :objectId="'new-child'"
          />
        </ObjectCard>

        <b-button-group v-if="!form"
                        class="w-100">
          <b-button class="btn-question mr-1"
                    @click="addSon">
            <i class="i-Male-2 text-25 font-weight-800 mr-2"> </i> Add Son
          </b-button>
          <b-button class="btn-question ml-1"
                    @click="addDaughter">
            <i class="i-Female-2 text-25 font-weight-800 mr-2"> </i> Add Daughter
          </b-button>
        </b-button-group>
      </div>
    </transition>
  </QuestionBase>

</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {childrenNumberHelpers} from "./childrenNumberHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import ChildFull from "../../../../fieldsets/sub/people/ChildFull";

export default {
  name: 'ChildrenFull',
  components: {
    ChildFull,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    objectSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    blankForms,
    childrenNumberHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    numberOfChildren: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      question: {
        title: 'Children',
        subTitle: 'Please add all of you biological or adopted children (even if they are over 18).',
        tip: null,
      },
      db: {
        saveLocation: 'client_person',
        saveField: 'children',
        deleteLocation: 'client_person',
        loadLocation: 'client_person_children',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false,
        callbackFunction: this.setNumberOfChildrenData,
        deleteCallbackFunction: this.setNumberOfChildrenData
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'Add your children to proceed',
        customErrorMessageNewOpenForm: "Save child before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    addSon() {
      this.form = null
      this.form = Object.assign({},
          this.personBlankForm)
      this.form.gender = 'male'
      this.form.relationship_to_client = 'son'
    },
    addDaughter() {
      this.form = null
      this.form = Object.assign({},
          this.personBlankForm)
      this.form.gender = 'female'
      this.form.relationship_to_client = 'daughter'
    }
  }
}
</script>
