// hardcoded for saving shares

import {httpQuestionnaire} from "@/services";
import {clone} from "@/mixins/clone";
import {scrollTo} from "@/mixins/scrollTo";

export const shareSaveHelpers = {
    name: 'shareSaveHelpers',
    mixins: [clone, scrollTo],
    methods: {
        saveShare(object, index, ref, form=null, callBackFunction = null) {
            if (object && index === null) this.post(object, ref, form, callBackFunction)
            else if (object && index >= 0) this.patch(object, index, ref, callBackFunction)
            else if (!object && ref) this.closeCard(ref, index, callBackFunction)
        },
        post(object, ref, form, callbackFunction) {
            this.clearShareErrors()

            let data = {
                value: object,
                path: this.db.formPath,
                save_field: this.db.jsonSaveField,
                // access_code: this.$store.getters.accessCode,
                form_type: form
            }

            httpQuestionnaire.post(this.db.saveLocation, data).then(
                () => {
                    // reset form
                    this.data.push(object)
                    this.form = null

                    if (this.jsErrors) this.jsErrors = []

                    // callback run
                    if (callbackFunction) callbackFunction()

                    // collapse item
                    this.$nextTick(() => {
                        if (ref) this.closeCard(ref)
                    })

                    this.$emit('clearAdditionalShareYN')

                }
            ).catch(
                error => {
                    this.handleShareErrors(error.response.data, 'errorsPost', ref)
                }
            )
        },
        patch(object, index, ref = null, callBackFunction = null) {
            this.clearShareErrors()
            let data = {
                value: object,
                path: `${this.db.formPath}.${index}`,
                save_field: this.db.jsonSaveField
                // access_code: this.$store.getters.accessCode
            }
            httpQuestionnaire.patch(this.db.saveLocation, data).then(
                () => {
                    // collapse item
                    if (ref) this.closeCard(ref)
                    if (callBackFunction) callBackFunction()
                }
            ).catch(
                error => {
                    this.handleShareErrors(error.response.data, 'errorsPost', ref)
                }
            )
        },
        deleteShareConfirm (index) {
            this.$bvModal.msgBoxConfirm('Are you sure you want to remove this share?',
                {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    cancelVariant: 'outline-primary',
                    okVariant: 'secondary',
                    okTitle: 'Confirm',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).
            then((confirmed) => {
                if (confirmed) {
                    this.deleteShare(index)
                }
            }).
            catch(() => {
                // An error occurred
            });
        },
        deleteShare(index) {
            this.clearShareErrors()
            this.data.splice(index, 1)
            // save everything
            this.save()

        },
        clearShareErrors() {
            this.errorsPost = {}
            this.errorsPatch = {}
        },
        handleShareErrors(errors, errorsLocation, ref) {
            let keys = Object.keys(errors)
            // if multiple forms with multiple errors of the same field then this will scroll to the first form
            // which may not be the desired behaviour
            this.scrollTo(`#${ref}${keys[0]}`)
            this[errorsLocation] = errors
        },
        closeCard(ref, callbackFunction = null) {
            if (ref in this.$refs) this.$refs[ref][0].show = false
            this.scrollTo(`#${ref}`)
            if (callbackFunction) callbackFunction()
        }
    }
}