<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Liabilities</h3>

    <LiabilitiesMortgageYN
        v-model="form.mortgage_debts"
        :liabilities="form.liabilities_mortgages"/>
    <div v-if="form.mortgage_debts"
         class="question-box with-arrow">
      <LiabilitiesMortgages
          ref="liabilitiesMortgages"
          v-model="form.liabilities_mortgages"
          @refresh="refreshLiabilities"/>
    </div>

    <LiabilitiesLoansYN
        v-model="form.unsecured_loans"
        :liabilities="form.liabilities_loans"/>
    <div
        v-if="form.unsecured_loans"
        class="question-box with-arrow">
      <LiabilitiesLoans
          ref="liabilitiesLoans"
          v-model="form.liabilities_loans"
          @refresh="refreshLiabilities"/>
    </div>

    <LiabilitiesCreditCardsYN
        v-model="form.credit_card_balances"
        :liabilities="form.liabilities_credit_cards"/>
    <div
        v-if="form.credit_card_balances"
        class="question-box with-arrow">
      <LiabilitiesCreditCards
          ref="liabilitiesCreditCards"
          v-model="form.liabilities_credit_cards"
          @refresh="refreshLiabilities"/>
    </div>

    <LiabilitiesOtherYN
        v-model="form.other_credit_agreements"
        :liabilities="form.liabilities_other"/>
    <div
        v-if="form.other_credit_agreements"
        class="question-box with-arrow">
      <LiabilitiesOther
          ref="liabilitiesOther"
          v-model="form.liabilities_other"
          @refresh="refreshLiabilities"/>
    </div>

    <h3 class="fieldset-heading mt-80">Credit History</h3>

    <BankruptYN v-model="form.profileBankruptcyIVAYN"/>
    <div v-if="form.profileBankruptcyIVAYN" class="question-box with-arrow">
      <BankruptDetails v-model="form.profileBankruptcyIVADetails"/>
    </div>

    <DebtManagementYN v-model="form.profileDebtManagementYN"/>
    <div v-if="form.profileDebtManagementYN" class="question-box with-arrow">
      <DebtManagementDetails v-model="form.profileDebtManagementDetails"/>
    </div>

    <CCJYN v-model="form.profileCCJYN"/>
    <div v-if="form.profileCCJYN" class="question-box with-arrow">
      <CCJDetails v-model="form.profileCCJDetails"/>
    </div>

    <MissedPaymentsYN v-model="form.profileMissedYN"/>
    <div v-if="form.profileMissedYN" class="question-box with-arrow">
      <MissedPaymentsDetails v-model="form.profileMissedDetails"/>
    </div>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import LiabilitiesMortgageYN from "../../question/questions/clientProfile/liabilities/LiabilitiesMortgageYN";
import LiabilitiesCreditCardsYN from "../../question/questions/clientProfile/liabilities/LiabilitiesCreditCardsYN";
import LiabilitiesLoansYN from "../../question/questions/clientProfile/liabilities/LiabilitiesLoansYN";
import LiabilitiesOtherYN from "../../question/questions/clientProfile/liabilities/LiabilitiesOtherYN";
import BankruptYN from "../../question/questions/clientProfile/liabilities/BankruptYN";
import CCJYN from "../../question/questions/clientProfile/liabilities/CCJYN";
import DebtManagementYN from "../../question/questions/clientProfile/liabilities/DebtManagementYN";
import MissedPaymentsYN from "../../question/questions/clientProfile/liabilities/MissedPaymentsYN";
import MissedPaymentsDetails from "../../question/questions/clientProfile/liabilities/MissedPaymentsDetails";
import CCJDetails from "../../question/questions/clientProfile/liabilities/CCJDetails";
import BankruptDetails from "../../question/questions/clientProfile/liabilities/BankruptDetails";
import DebtManagementDetails from "../../question/questions/clientProfile/liabilities/DebtManagementDetails";
import LiabilitiesMortgages from "../../question/questions/clientProfile/liabilities/LiabilitiesMortgages";
import LiabilitiesOther from "../../question/questions/clientProfile/liabilities/LiabilitiesOther";
import LiabilitiesCreditCards from "../../question/questions/clientProfile/liabilities/LiabiltiesCreditCards";
import LiabilitiesLoans from "../../question/questions/clientProfile/liabilities/LiabilitiesLoans";

export default {
  name: 'LiabilitiesFull',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    LiabilitiesLoans,
    LiabilitiesCreditCards,
    LiabilitiesOther,
    LiabilitiesMortgages,
    DebtManagementDetails,
    BankruptDetails,
    CCJDetails,
    MissedPaymentsDetails,
    MissedPaymentsYN,
    DebtManagementYN,
    CCJYN,
    BankruptYN,
    LiabilitiesOtherYN,
    LiabilitiesLoansYN,
    LiabilitiesCreditCardsYN,
    LiabilitiesMortgageYN

  },
  data() {
    return {
      label: 'Liabilities' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refreshLiabilities() {
      // use only one liabilities question to refresh them all
      if ('liabilitiesMortgages' in this.$refs) this.$refs.liabilitiesMortgages.get()
      if ('liabilitiesLoans' in this.$refs) this.$refs.liabilitiesLoans.get()
      if ('liabilitiesCreditCards' in this.$refs) this.$refs.liabilitiesCreditCards.get()
      if ('liabilitiesOther' in this.$refs) this.$refs.liabilitiesOther.get()
    }
  }
};
</script>
