import { blankForms } from "../../../question/helpers/blankForms";
import { numberFormat } from "@/mixins/numberFormat";

export const willDistributionHelpers = {
    mixins: [blankForms, numberFormat],
    data () {
        return {
            dbLocationConvert: {
                // used to convert save location for different levels of distribution
                distributionPrimary: ['dbPrimary', 'dataPrimary'],
                distributionSecondary: ['dbSecond', 'dataSecond'],
                distributionBackup: ['dbBackup', 'dataBackup']
            }
        }
    },
    mounted() {
        // calculate people options
        this.setOptionsData()
        // for old created will insert new fields into data
        // gifts first
        let attributeAddedFirst = false
        if (!('partnerPrimaryYN' in this.dataPrimary)) {
            this.$set(this.dataPrimary, 'partnerPrimaryYN', null)
            attributeAddedFirst = true
        }
        if (!('additionalSharesYN' in this.dataPrimary)) {
            this.$set(this.dataPrimary, 'additionalSharesYN', null)
            attributeAddedFirst = true
        }
        if (attributeAddedFirst) {
            let convert = this.dbLocationConvert.distributionPrimary
            this.save(convert[0], convert[1])
        }

        // distribution second
        let attributeAddedSecond = false
        if (!('additionalSharesYN' in this.dataSecond)) {
            // set new fields for old created wills
            this.$set(this.dataSecond, 'additionalSharesYN', null)
            attributeAddedSecond = true
        }
        if (!('childrenSecondaryYN' in this.dataSecond)) {
            this.$set(this.dataSecond, 'childrenSecondaryYN', null)
            attributeAddedSecond = true
        }
        if (attributeAddedSecond) {
            let convert = this.dbLocationConvert.distributionSecondary
            this.save(convert[0], convert[1])
        }

        // distribution backup
        let attributeAddedBackup = false
        if (!('additionalSharesYN' in this.dataBackup)) {
            this.$set(this.dataBackup, 'additionalSharesYN', null)
            attributeAddedBackup = true
        }
        if (!('backupYN' in this.dataBackup)) {
            this.$set(this.dataBackup, 'backupYN', null)
            attributeAddedBackup = true
        }
        if (attributeAddedBackup) {
            let convert = this.dbLocationConvert.distributionBackup
            this.save(convert[0], convert[1])
        }

        let primaryShareRemoved = false
        // remove shares with no recipients
        for (let i = 0; i < this.dataPrimary.shares.length; i++) {
            if (!this.dataPrimary.shares[i].recipients.length) {
                // remove share
                this.form.data.distributionPrimary.details.shares.splice(i, 1)
                primaryShareRemoved = true
            }
        }

        if (primaryShareRemoved) {
            // save
            let convert = this.dbLocationConvert.distributionPrimary
            this.save(convert[0], convert[1])
        }

        let secondaryShareRemoved = false
        // remove shares with no recipients
        for (let i = 0; i < this.dataSecond.shares.length; i++) {
            if (!this.dataSecond.shares[i].recipients.length) {
                // remove share
                this.form.data.distributionSecondary.details.shares.splice(i, 1)
                secondaryShareRemoved = true
            }
        }

        if (secondaryShareRemoved) {
            // save
            let convert = this.dbLocationConvert.distributionSecondary
            this.save(convert[0], convert[1])
        }

        let backupShareRemoved = false
        // remove shares with no recipients
        for (let i = 0; i < this.dataBackup.shares.length; i++) {
            if (!this.dataBackup.shares[i].recipients.length) {
                // remove share
                this.form.data.distributionBackup.details.shares.splice(i, 1)
                backupShareRemoved = true
            }
        }

        if (backupShareRemoved) {
            // save
            let convert = this.dbLocationConvert.distributionBackup
            this.save(convert[0], convert[1])
        }


    },
    watch: {
        storePeople: {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        'dataPrimary.shares': {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        'dataSecond.shares': {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        },
        'dataBackup.shares': {
            immediate: false,
            deep: true,
            handler() {
                this.setOptionsData()
            }
        }
    },
    computed: {
        storePeople() {
            return this.$store.getters.people
        },
        // needed for saving
        dataPrimary () {
            return this.form.data.distributionPrimary.details
        },
        dataSecond () {
            return this.form.data.distributionSecondary.details
        },
        dataBackup () {
            return this.form.data.distributionBackup.details
        },
        primaryQuestionTitle () {
            return this.dataPrimary.partnerPrimaryYN ? '' : 'Who would you like as your primary beneficiaries?'
        },
        secondaryQuestionTitle () {
            return 'Who would you like as your secondary beneficiaries?'
        },
        backupQuestionTitle () {
            return 'Who would you like as your backup beneficiaries?'
        }
    },
    methods: {
        setNewShareBlank (location) {
            this.form.data[location].details.additionalShareYN=null
            this.$nextTick(() => {
                this.$refs.firstAdditionalGifts.$refs.input.save()
            })
        },
        resetDistribution(location) {
            this.form.data[location].details.shares = []
            this.$nextTick(() => {
                let convert = this.dbLocationConvert[location]
                this.save(convert[0], convert[1])
            })
        },
        setOptionsData() {
            // client_people from server with originally selected people filtered out,
            // mapped to be compatible with a Will then people relationships converted
            let serverPrimary = []
            let serverSecondary = []
            let serverBackup = []

            // convert people
            let serverPeople = this.storePeople.map(person => {
                // do not convert professional as they have been added from TypeOfExecutors in final form for will
                if (person.type === 'professional') return person
                // convert ClientPerson model into will person
                return this.convertPerson(person)
            })

            // filter out primary, secondary or backup options
            let primaryIds = this.dataPrimary.shares.reduce((result, share) => share.recipients.map(person => person.id).concat(result), [])
            let secondaryIds = this.dataSecond.shares.reduce((result, share) => share.recipients.map(person => person.id).concat(result), [])
            let backupIds = this.dataBackup.shares.reduce((result, share) => share.recipients.map(person => person.id).concat(result), [])

            serverPrimary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))
            serverSecondary = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))
            serverBackup = serverPeople.filter(person => !primaryIds.includes(person.id) && !secondaryIds.includes(person.id) && !backupIds.includes(person.id))

            // return
            this.primaryOptions = [...serverPrimary].sort((a, b) => a.id - b.id)
            this.secondaryOptions = [...serverSecondary].sort((a, b) => a.id - b.id)
            this.backupOptions = [...serverBackup].sort((a, b) => a.id - b.id)
        },
        handlePartnerAsPrimary () {
            if (this.dataPrimary.partnerPrimaryYN) this.addPartnerAsPrimary()
            else this.resetShares('distributionPrimary')
        },
        addPartnerAsPrimary () {
            let partner = this.clone(this.convertPerson(this.partner))
            let newShare = this.clone(this.distributionBlankShareForm)
            newShare.stirpes = false
            newShare.recipients.push(partner)
            this.form.data.distributionPrimary.details.shares = []
            this.form.data.distributionPrimary.details.shares.push(newShare)
            this.$nextTick(() => {
                let convert = this.dbLocationConvert.distributionPrimary
                this.save(convert[0], convert[1])
            })
        },
        handleChildrenAsSecondary() {
            if (this.dataSecond.childrenSecondaryYN) this.addChildrenAsSecondary()
            else this.resetShares('distributionSecondary')
        },
        addChildrenAsSecondary() {
            // add each child into their own share
            this.form.data.distributionSecondary.details.shares = []
            let percentage = this.roundToTwo(Number(100 / this.children.length))

            for (let i = 0; i < this.children.length; i++) {
                let newShare = this.clone(this.distributionBlankShareForm)
                newShare.share = `${percentage}%`
                newShare.recipients.push(this.clone(this.convertPerson(this.children[i])))
                this.form.data.distributionSecondary.details.shares.push(newShare)
            }
            this.$nextTick(() => {
                let convert = this.dbLocationConvert.distributionSecondary
                this.save(convert[0], convert[1])
            })
        },
        handleBackup() {
            if (!this.dataBackup.backupYN) this.resetShares('distributionBackup')
        },
        handleBackupShare(location, shareIndex) {
            // clears backup recipients
            for (let i = 0; i < this.form.data[location].details.shares[shareIndex].recipients.length; i++) {
                this.form.data[location].details.shares[shareIndex].recipients[i].recipients = []
            }

            this.$nextTick(() => {
                let convert = this.dbLocationConvert[location]
                this.save(convert[0], convert[1])
            })

        },
        resetShares(location) {
            this.form.data[location].details.shares = []
            this.$nextTick(() => {
                let convert = this.dbLocationConvert[location]
                this.save(convert[0], convert[1])
            })
        },
    }
}
