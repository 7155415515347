<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="executorsTypeOfOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        class="radio-options-wide"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { willOptions } from "../../../options/willOptions";
import { willRelationshipHelpers } from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import { willPeopleHelpers } from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'TypeOfExecutors',
  mixins: [questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, willOptions],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    callbackFunction: {
      type: Function,
      required: true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'What type of executors would you like to appoint?',
        subTitle: 'For uncomplicated estates, relatives or friends may be suitable. Although appointing professionals helps to remove the stress and hassle from the process.',
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: 'typeOfExecutors',
        formPath: 'data.executors.details.typeOfExecutors',
        jsonSaveField: 'typeOfExecutors',
        callbackFunction: this.callbackFunction
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false
    }
  }
}
</script>
