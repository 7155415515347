<template>
  <QuestionBase
      :question="question"
      :errors="[...jsErrors]"
      :valid="data !== null"
  >
    <InputRadio
        v-model="data"
        :db="db"
        :options="booleanYesNo"
    />
  </QuestionBase>
</template>

<script>
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {textHelpers} from "@/mixins/textHelpers";
import {personHelpers} from "@/mixins/personHelpers";
import {shareSaveHelpers} from "./shareSaveHelpers";
import {general} from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'ShareStirpes',
  components: {
    InputRadio,
    QuestionBase
  },
  mixins: [general, questionLoadHelpers, blankForms, textHelpers, shareSaveHelpers, saveHelpers, personHelpers],
  props: {
    value: {
      type: Boolean,
      required: false
    },
    location: {
      type: String,
      required: true
    },
    share: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}Shares${this.index}Stirpes`,
        formPath: `data.${this.location}.details.shares.${this.index}.stirpes`,
        jsonSaveField: 'stirpes',
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      sharesMainInput: [],
      addShareYN: null,
      show: {
        addPerson: false
      },
    }
  },
  computed: {
    question() {
      let questionTitle = ''
      if (this.hasGroupInRecipients) {
        questionTitle = `If any of ${this.listPeopleAddressDob({
          people: this.share.recipients,
          noDob: true,
          firstName: true,
          andOr: ' or '
        })} have passed away before you, would you like their share to pass to their children instead?`
      } else if (this.multipleRecipients) {
        questionTitle = `If ${this.listPeopleAddressDob({
          people: this.share.recipients,
          noDob: true,
          firstName: true,
          andOr: ' or '
        })} cannot inherit because they have passed away before you, would you like their share to pass to their children instead (if they have children at the time)?`
      } else if (this.soleRecipient) {
        questionTitle = `If ${this.listPeopleAddressDob({
          people: this.share.recipients,
          noDob: true,
          firstName: true,
          andOr: ' or '
        })} is not able to inherit because ${this.heShe(this.soleRecipient.gender)} has passed away before you, would you like ${this.hisHer(this.soleRecipient.gender)} share to be given to ${this.hisHer(this.soleRecipient.gender)} children instead (assuming ${this.heShe(this.soleRecipient.gender)} has a child or children at the time)?`
      }

      return {
        title: questionTitle,
        subTitle: null,
        tip: null,
      }
    },
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    hasGroupInRecipients() {
      return !!this.share.recipients.filter(option => option.type === 'group').length
    },
    multipleRecipients() {
      return this.share.recipients.length > 1
    },
    soleRecipient() {
      if (this.share.recipients.length === 1) {
        return this.share.recipients[0]
      }
      return false
    }
  }
}
</script>
