<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">

    <!-- Selected People -->
    <div class="question-box with-arrow mt-32">

      <div class="accordion sub-items">
        <transition-group name="fade">
          <ObjectCard v-for="(person, i) in data"
                      :id="db.saveField + i"
                      :key="db.saveField + i"
                      :ref="db.saveField + i"
                      :select-mode="true"
                      :selected="true"
                      :title="cardTitle(person)"
                      :value="data[i]"
                      :show-delete="false"
                      :index="i"
                      type="recipientsData"
                      :loading="loading"
                      @click="deselectConfirm(person, 'Remove person from the backups?')"
                      @delete="deletePerson(person.id)"
                      @save="savePerson( null, db.saveField + i)">

            <PersonWill v-if="person.type === 'person'"
                        v-model="data[i]"
                        :key="'recipients-form-data' + i"
                        :errors-post="errorsPatch"
                        :no-save="true"
                        :objectId="person.id"
                        @save="savePersonForm($event, person)"
            />

            <GroupWill
                v-if="person.type === 'group'"
                v-model="data[i]"
                :key="'recipients-form-data' + i"
                @save="savePersonForm($event, person)"/>

            <CharityWill
                v-if="person.type === 'charity'"
                v-model="data[i]"
                :key="'recipients-form-data' + i"
                @save="savePersonForm($event, person)"
            />

          </ObjectCard>
        </transition-group>
      </div>


      <!-- New People -->
      <transition name="fade">
        <b-button
            class="btn-question w-100"
            @click="show.addPerson=true"
        >
          <i class="i-Add text-25 font-weight-800 mr-2"> </i> Add Backup for {{ fullName(details, true) }}
        </b-button>
      </transition>

    </div>

    <WillPeopleModal
        v-model="show.addPerson"
        :options="optionsData"
        :dataSelected="data"
        :show-charities="true"
        :show-groups="true"
        :title="`Add Backup Recipients for ${fullName(details, true)}`"
        @dataSelectedUpdate="data=$event"
        @save="save"
    />

  </QuestionBase>
</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {peopleSaveHelpers} from "../../objects/peopleSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";
import {willPeopleObjectHelpers} from "../helpers/willPeopleObjectHelpers";
import {willPeopleHelpers} from "../../../../fieldsets/will/helpers/willPeopleHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import PersonWill from "../../../../fieldsets/sub/people/PersonWill";
import WillPeopleModal from "../helpers/WillPeopleModal";
import GroupWill from "../../../../fieldsets/sub/people/GroupWill";
import CharityWill from "../../../../fieldsets/sub/people/CharityWill";

export default {
  name: 'ShareBackupRecipients',
  components: {
    CharityWill,
    GroupWill,
    WillPeopleModal,
    PersonWill,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    peopleSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    saveHelpers,
    willPeopleObjectHelpers,
    willPeopleHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    details: {
      type: Object,
      required: true
    },
    optionsData: {
      type: Array,
      required: true
    },
    location: {
      type: String,
      required: true
    },
    recipientIndex: {
      type: Number,
      required: true
    },
    shareIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      db: {
        saveLocation: 'product_will_data',
        saveField: `${this.location}Shares${this.shareIndex}Recipient${this.recipientIndex}Recipients`,
        formPath: `data.${this.location}.details.shares.${this.shareIndex}.recipients.${this.recipientIndex}.recipients`,
        jsonSaveField: 'recipients',
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'You must have more than one Primary Executor, click to select'
      },
      serverErrors: [],
      errorsPatch: {},
      jsErrors: [],
      skipped: false,
      show: {
        addPerson: false
      }
    }
  },
  computed: {
    question() {
      return {
        title: `Who then will benefit from this share if ${this.listPeopleAddressDob({
          people: [this.details],
          noDob: true
        })} has passed away before you?`,
        subTitle: null,
        tip: null
      }
    },
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
  },

}
</script>
